<template>
  <div>
    <h1>{{ $t("time.title") }}</h1>
    <calendarTime />
  </div>
</template>
<script>
export default {
  components: {
    calendarTime() {
      return import("@/components/private/time/index.vue");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
